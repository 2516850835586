import React from "react";
import { Link } from "gatsby";
import { GoBook } from "@react-icons/all-files/go/GoBook";
import { BiCoffee } from "@react-icons/all-files/bi/BiCoffee";
import { BiCookie } from "@react-icons/all-files/bi/BiCookie";
import { FaRegHeart } from "@react-icons/all-files/fa/FaRegHeart";
import { IoFastFoodOutline } from "@react-icons/all-files/io5/IoFastFoodOutline";

const smooth = "transition-all ease-in-out duration-300";

const FilterMenu = () => {
  return (
    <div className="relative flex justify-center items-center mt-2 mb-3 md:mb-0 md:mt-0">
      {/* mobile */}
      <div
        className={`relative mt-2 px-1 py-1 rounded-full bg-stone-200 flex md:hidden justify-center items-center space-x-3.5`}
      >
        <Link
          to="/menu"
          className={`z-10 relative p-3 rounded-full grid place-content-center bg-stone-300`}
          activeClassName={`bg-blue-710 text-white`}
        >
          <GoBook className="text-3xl " />
        </Link>
        <Link
          to="/menu/favorite"
          className={`z-10 relative p-3 rounded-full grid place-content-center bg-stone-300`}
          activeClassName={`bg-blue-710 text-white`}
        >
          <FaRegHeart className="text-[1.7rem] " />
        </Link>
        <Link
          to="/menu/drink"
          className={`z-10 relative p-3 rounded-full grid place-content-center bg-stone-300`}
          activeClassName={`bg-blue-710 text-white`}
        >
          <BiCoffee className="text-3xl " />
        </Link>
        <Link
          to="/menu/bakery"
          className={`${smooth} z-10 relative p-3 rounded-full grid place-content-center bg-stone-300`}
          activeClassName={`bg-blue-710 text-white`}
        >
          <BiCookie className="text-3xl" />
        </Link>
        <Link
          to="/menu/food"
          className={`${smooth} z-10 relative p-3 rounded-full grid place-content-center bg-stone-300`}
          activeClassName={`bg-blue-710 text-white`}
        >
          <IoFastFoodOutline className="text-3xl" />
        </Link>
      </div>

      {/* desktop */}
      <div
        className={`md:mt-8 hidden md:flex justify-center items-center w-auto bg-white py-3 md:px-8 rounded-xl shadow-lg shadow-stone-900/10 drop-shadow-lg`}
      >
        <Link
          to="/menu"
          className={`${smooth} hover:bg-blue-710 hover:text-white rounded-xl font-lufga400 px-3 lg:px-6 py-4 text-xl`}
          activeClassName={`${smooth} bg-blue-710 text-white`}
        >
          <GoBook className="text-[2rem] hidden md:flex" />
        </Link>

        <div className="w-[2px] h-[30px] bg-slate-300 ml-5 lg:ml-6 hidden md:flex" />

        <Link
          to="/menu/favorite"
          className={`${smooth} hover:bg-blue-710 hover:text-white rounded-xl font-lufga400 px-3 lg:px-6 py-4 text-xl ml-1.5 lg:ml-3`}
          activeClassName={`${smooth} bg-blue-710 text-white`}
        >
          Customer's Fav
        </Link>
        <Link
          to="/menu/drink"
          className={`${smooth} hover:bg-blue-710 hover:text-white rounded-xl font-lufga400 px-3 lg:px-6 py-4 text-xl ml-1.5 lg:ml-3`}
          activeClassName={`${smooth} bg-blue-710 text-white`}
        >
          Drinks
        </Link>
        <Link
          to="/menu/bakery"
          className={`${smooth} hover:bg-blue-710 hover:text-white rounded-xl font-lufga400 px-3 lg:px-6 py-4 text-xl ml-1.5 lg:ml-3`}
          activeClassName={`${smooth} bg-blue-710 text-white`}
        >
          Bakery
        </Link>
        <Link
          to="/menu/food"
          className={`${smooth} hover:bg-blue-710 hover:text-white rounded-xl font-lufga400 px-3 lg:px-6 py-4 text-xl ml-1.5 lg:ml-3`}
          activeClassName={`${smooth} bg-blue-710 text-white`}
        >
          Food
        </Link>
      </div>
    </div>
  );
};

export default FilterMenu;
